import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import {
  Message,
  GetMemberConsultationsItem,
  MessageType,
  getMemberConsultationOutcome,
} from "../../../../services/core-api-adapter";
import MemberConsultationCard from "../../../Common/components/MemberConsultationCard";

interface ContinueConsultMessageCardProps {
  message: Message;
}

export default function ContinueConsultMessageCard({
  message,
}: ContinueConsultMessageCardProps) {
  const navigate = useNavigate();
  const [consultation, setConsultation] =
    useState<GetMemberConsultationsItem | null>(null);

  const { firstMembership } = useGetMemberships();

  function getMemberName(memberId: any) {
    return (
      firstMembership.members.find(
        (dependent) => dependent.memberDetails.id === memberId
      )?.memberDetails?.memberFullName || ""
    );
  }

  function onConsultationClick() {
    const consultationId =
      message.meta?.find(
        (item: any) => item.key.toLowerCase() === "consultationid"
      )?.value || "";
    navigate(`/home/get-medical-assistance/intercare/${consultationId}`);
  }

  useEffect(() => {
    if (message && message.messageType === MessageType.CONSULT_CONTINUE) {
      const consultationId =
        message.meta?.find(
          (item) => item.key.toLowerCase() === "consultationid"
        )?.value || "";

      if (consultationId) {
        getMemberConsultationOutcome(consultationId).then((consultation) => {
          setConsultation(consultation as GetMemberConsultationsItem);
        });
      }
    }
  }, [message]);

  return (
    <>
      {consultation && (
        <MemberConsultationCard
          memberName={getMemberName(consultation.memberId)}
          consultation={consultation}
          onConsultationClick={onConsultationClick}
          shouldDisplayStatus={true}
        />
      )}
    </>
  );
}
