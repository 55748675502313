import { Stack, Box, Typography, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import IconLoader from "../../../Common/components/IconLoader";
import LoadingSpinner from "../../../Common/components/LoadingSpinner";
import SlideUpDialog from "../../../Common/components/SlideUpDialog";

interface ConfirmMessageDeleteProps {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isModalOpen: boolean;
  onMessageDelete: () => void;
  onModalClose: () => void;
  onMessageDeleteSuccessClose: () => void;
}

export function ConfirmMessageDelete({
  isLoading,
  isError,
  isSuccess,
  isModalOpen,
  onMessageDelete,
  onModalClose,
  onMessageDeleteSuccessClose,
}: ConfirmMessageDeleteProps) {
  const { t } = useTranslation();

  return (
    <SlideUpDialog
      isOpen={isModalOpen}
      onClose={() => onModalClose()}
      label={t("Messages.DeleteMessageModal.label")}
    >
      <Stack component="article" spacing={2}>
        {!isError && !isSuccess && !isLoading && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="DeleteMessageIcon"
                color="error"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("Messages.DeleteMessageModal.title")}
            </Typography>

            <Typography textAlign="center">
              <Trans>{t("Messages.DeleteMessageModal.body")}</Trans>
            </Typography>

            <Stack component="footer" spacing={1} direction="row">
              <Button
                size="small"
                variant="outlined"
                fullWidth
                onClick={onModalClose}
              >
                {t("common.no")}
              </Button>
              <Button onClick={onMessageDelete} size="small" fullWidth>
                {t("common.yes")}
              </Button>
            </Stack>
          </>
        )}

        {!isError && !isSuccess && isLoading && (
          <Box textAlign="center">
            <LoadingSpinner />
          </Box>
        )}

        {isError && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="DeleteMessageIcon"
                color="error"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>
            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("Messages.DeleteMessageModal.error.title")}
            </Typography>

            <Typography textAlign="center">
              {t("Messages.DeleteMessageModal.error.body")}
            </Typography>
            <Button size="small" fullWidth onClick={onModalClose}>
              {t("common.close")}
            </Button>
          </>
        )}

        {isSuccess && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="DeleteMessageIcon"
                color="error"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("Messages.DeleteMessageModal.success.title")}
            </Typography>

            <Typography textAlign="center">
              {t("Messages.DeleteMessageModal.success.body")}
            </Typography>

            <Stack component="footer">
              <Button
                size="small"
                fullWidth
                onClick={onMessageDeleteSuccessClose}
              >
                {t("common.close")}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </SlideUpDialog>
  );
}
