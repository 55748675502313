import { Stack } from "@mui/material";
import { MessageDetailType } from "../../../../../../services/core-api-adapter";
import { convertPxToRem } from "../../../../../../utils";
import IconLoader from "../../../../../Common/components/IconLoader";

interface MessageDetailTypeIconProps {
  detailType: MessageDetailType;
}

const messageDetailTypeModels = {
  Welcome: {
    icon: (
      <IconLoader
        icon="LoudspeakerIcon"
        color="accent1"
        sx={{ fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent1.100",
  },
  SaturationOfPeripheralOxygen: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  HeartRate: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  BreathingRate: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  DiastolicBloodPressure: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  SystolicBloodPressure: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  HBA1C: {
    icon: (
      <IconLoader
        icon="NoticeIcon"
        sx={{ color: "accent3.main", fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent3.100",
  },
  DoctorFollowUp: {
    icon: (
      <IconLoader
        icon="CardIcon"
        color="primary"
        sx={{ fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "primary.100",
  },
  FaceScanReminder: {
    icon: (
      <IconLoader
        icon="SmilingFaceInRoundedSquareIcon"
        color="primary"
        sx={{ fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "primary.100",
  },
  DoctorConsult: {
    icon: (
      <IconLoader
        icon="LoudspeakerIcon"
        color="accent1"
        sx={{ fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent1.100",
  },
  PrestigeUnlock: {
    icon: (
      <IconLoader
        icon="LoudspeakerIcon"
        color="accent1"
        sx={{ fontSize: convertPxToRem(18) }}
      />
    ),
    backgroundColour: "accent1.100",
  },
  EducationAndEngagement: {
    icon: (
      <IconLoader
        icon="InformationSymbolInCircleIcon"
        color="accent1"
        sx={{ fontSize: 18 }}
      />
    ),
    backgroundColour: "accent1.100",
  },
  OnboardingComplete: {
    icon: (
      <IconLoader
        icon="InformationSymbolInCircleIcon"
        color="accent1"
        sx={{ fontSize: 18 }}
      />
    ),
    backgroundColour: "accent1.100",
  },
  Unknown: {
    icon: (
      <IconLoader
        icon="InformationSymbolInCircleIcon"
        color="primary"
        sx={{ fontSize: 18 }}
      />
    ),
    backgroundColour: "primary.100",
  },
};

export default function MessageDetailTypeIcon({
  detailType,
}: MessageDetailTypeIconProps) {
  const data =
    messageDetailTypeModels[detailType] || messageDetailTypeModels.Unknown;

  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="center"
      alignContent="center"
      alignSelf="flex-start"
      borderRadius={2}
      spacing={0.25}
      p={1}
      bgcolor={data.backgroundColour}
      sx={{
        height: "auto",
        width: "auto",
      }}
    >
      {data.icon}
    </Stack>
  );
}
